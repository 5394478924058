import React from 'react'
import Header from './Header.js'
import './App.css'
import retreatsImage from './images/retreats-big.png'

export default function Retreats() {
  return (
    <>
	    <Header />

      <div>
        <h1 style={{paddingBottom: '2rem'}}>Retreat in Colombia, South America – physical and spiritual healings</h1>
        <p style={{fontStyle: 'italic', width: '77%', margin: 'auto'}}>Join us in Colombia for a week and live like a yogi and shaman. Our healing ceremonies can bring profound shifts of consciousness that can bring lasting healing and positivity in your life.</p>
      </div>

      <img src={retreatsImage} alt="retreats" className='retreat-img' />
	
      <div className='community-container'>
        <h2>Who it’s for</h2>

        <p>This is for people who are okay camping for a week. We will be living in a rustic, austere way – yet it may surprise you how freeing it feels.</p>
        <p>Our retreats are yogic and shamanic in nature. That means we will be living like yogis and shamans, and also participating in ancient healing practices – sweat lodges, plant medicines, meditations, etc.</p>
        <p>This experience is the real deal. This is if you’re ready to go all the way with exploring your own consciousness and if you’re committed to deep healing, even if it may be difficult.</p>
        <p>At our retreas we are not dancing around our problems and issues. Here we are pointing straight at our deepest traumas and problems and bringing loving awareness to them. Awareness itself contains healing properties.</p>      
        <p>During the week we will be using many different plant medicines from South American shamanism. They are 100% natural, organic, and have zero negative side effects. Their main function is to induce a temporary expansion in consciousness.</p>
        <p>During this expansion of consciousness, you will have a similar ability to what a yogi or mystic may experience in a state of deep Nirvana or Zen. The healing power of experiencing this universal love is unmatched, and it’s often this mystical and spiritual experience that brings the deepest and most permanent healings for people.</p>
        <p>If this sounds interesting and beneficial, you will love this retreat.</p>

        <h2 style={{marginTop: '3rem'}}>What it helps you achieve</h2>

        <p>Experiencing this retreat will transform your life in the following ways:</p>
        
        <ul style={{textAlign: 'left'}}>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem', marginTop: '2rem'}}><b>Deep healing of the mind, body and soul –</b> Your vibration will shift to a healing frequency, and this allows deep healing on all levels of the human system.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Expansion of consciousness -</b> Participate in healing practices useful for expanding consciousness, an experience which can leave lasting benefits in your life. This can have huge positive effects that change your life for the better.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Connection to the Divine -</b> Nothing is guaranteed at our retreats, but people have often said they experienced a divine connection at some point during the week. Important so you don’t lose faith in life.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Meet other enlightened beings -</b> Living with mystics, yogis and healers for a week, you can absorb their vibration and take it with you, elevating you and giving you more tools for succeeding in life.</li>
        </ul>


        <h2 style={{marginTop: '3rem'}}>How it works</h2>

        <p>The retreat cost is $1450 USD for a 7 day retreat.</p>
        <p>Send us an email  at healingwandtribe@gmail.com and once payment is confirmed, we send you details on where to arrive in Colombia. Once you arrive in Colombia, we will handle all logistics so you can focus 100% on your own healing & spiritual journey.</p>


        <h2 style={{marginTop: '3rem'}}>How to get started</h2>

        <p>If you’re interested in joining us in Colombia, send an email at <b>healingwandtribe@gmail.com</b></p>
      </div>
  	</>
	)
}
