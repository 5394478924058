import React, { Component } from 'react';
import logo from './images/healing-wand-logo.svg';
import { Link } from "react-router-dom";

export default class Header extends Component {
  render() {
    return (
			<header className="App-header">
				<Link to="/">
					<img src={logo} className="App-logo" alt="logo" />
				</Link>

				<div className="menu-container">
					<a target='_blank' rel="noreferrer" href="https://www.youtube.com/@healing-wand?sub_confirmation=1" className="nav-link">
						YouTube
	        </a>
					
					<Link to="/newsletter" className='nav-link'>
						Newsletter
					</Link>

					<Link to="/about" className='nav-link'>
						About
					</Link>

					<Link to="/login" className='nav-link'>
						Login
					</Link>
				</div>
			</header>
    )
  }
}
