import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home.js';
import Newsletter from './Newsletter';
import NewsletterConfirm from './NewsletterConfirm.js';
import About from './About';
import OnlineCommunity from './OnlineCommunity';
import Retreats from './Retreats';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/newsletter" element={<Newsletter/>} />
          <Route path="/newsletter-confirm" element={<NewsletterConfirm/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/online-community" element={<OnlineCommunity/>} />
          <Route path="/retreats" element={<Retreats/>} />
          <Route path="/" element={<Home/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;