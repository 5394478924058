import React from 'react'
import Header from './Header.js'
import {Link} from 'react-router-dom'
import Home from './Home.js'
import './App.css'

export default function NewsletterConfirm() {
  return (
    <>
			<Header />

			<div>
				<div className='newsletter-text'>
					<h1 style={{paddingBottom: '2rem'}}>You’re on the list!</h1>
					<p>Expect 3 quality emails on Monday, Wednesday and Friday every week.</p>
					<p>Have a wonderful day :) </p>
				</div>

                <div className='newsletter-button-container'>
                    <Link to="/" element={<Home />} className='button'>
                        Back to Home
                    </Link>
                </div>
                
				
			</div>
  	</>
	)
}
