import React from 'react'
import Header from './Header.js'
import { useForm } from 'react-hook-form'
import './App.css'

export default function Newsletter() {
  const { register, handleSubmit } = useForm();

	const onSubmit = (data) => {
		alert(JSON.stringify(data))
	}

  return (
    <>
			<Header />
			<div style={{marginTop: '4rem'}}>
				<iframe src="https://embeds.beehiiv.com/b038d0df-2a97-41fc-9370-2184348ea6f6" data-test-id="beehiiv-embed" width="100%" height="320" frameborder="0" scrolling="no" style={{ borderRadius: '4px', border: '2px solid #e5e7eb', margin: '0', backgroundColor: 'transparent' }}></iframe>
			</div>
  	</>
	)
}
