import React from 'react'
import Header from './Header.js'
import './App.css'
import communityImg from './images/ganga.png'

export default function OnlineCommunity() {
  return (
    <>
	    <Header />

      <div>
        <h1 style={{paddingBottom: '2rem'}}>Be part of a community focused on health and enlightenment</h1>
        <p style={{fontStyle: 'italic', width: '77%', margin: 'auto'}}>The Healing Wand Community is an online haven of health & spiritual knowledge and wisdom that shows you how to live according to the universal laws of nature in order to live an excellent and inspiring life.</p>
      </div>

      <img src={communityImg} alt="online community" className='community-img' />
	
      <div className='community-container'>
        <h2>Who it’s for</h2>

        <p>The Healing Wand Community is for you if you are interested in elevating your life to the highest levels in terms of health and spiritual awareness.</p>
        <p>Or perhaps you’ve gone through a difficult situation in life, maybe an illness, or a traumatic event, and you are looking for help with processing emotions, getting your physical health back on track, and moving forward in life.</p>
        <p>Maybe you are someone who has wondered about how the universe works, how the human body actually operates, and what “enlightenment” is.</p>
        <p>If you are interested in healing, enlightenment, and gaining a cultivated awareness of life, you will love being a part of our community.</p>      

        <h2 style={{marginTop: '3rem'}}>What it helps you achieve</h2>

        <p>Being part of this community will transform your life in the following ways:</p>
        
        <ul style={{textAlign: 'left'}}>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem', marginTop: '2rem'}}><b>Real World Results –</b> The more progress you make in your healing & spiritual journey, the more real-life results you will see as you easily manifest the life you want. Family, relationships, careers, health, happiness – every aspect of life gets better.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Healthy mind, body, and soul -</b> Allows you to live always as a healthy, happy person, so you always have energy, strength, and desire to live life to the fullest.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Strengthen your soul connection -</b> Learn how to discover your True Path by learning to tap into your deeper intelligence.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Learn the Universal Laws -</b> See the world like an enlightened person, from a higher level of awareness, and use the power of the Laws to create the life you want.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Build life-long friendships –</b> Join our tribe and connect to people that are committed to living a life of growth, healing, and spirituality.</li>
        </ul>


        <h2 style={{marginTop: '3rem'}}>How it works</h2>

        <p>Anyone from around the world with an internet connection can join our online community for only $9 USD / month. Your membership renews automatically every month on the same date that you joined. You can cancel anytime with just a click of a button.</p>
        <p>Inside the community, you will have access to:</p>

        <ul style={{textAlign: 'left'}}>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem', marginTop: '1.5rem'}}><b>Library of Wisdom -</b> a special collection of wisdom on health, spirituality, the human energy system, universal laws, and more. You can access this 24/7 online as a member.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>24/7 Chat -</b> Talk with the tribe or DM individual members, connect with people around the world, and help us grow the global spiritual community.</li>
          <li style={{lineHeight: '1.5rem', marginBottom: '1rem'}}><b>Weekly live calls</b> with our team where you can share your story and ask specific questions and get to know each other.</li>
        </ul>

        <p>The community runs entirely on the Skool platform. You only need one single login to get access to all the courses, community chat rooms, and live calls.</p>
        
        <h2 style={{marginTop: '3rem'}}>How to get started</h2>

        <p>If you’re ready to start your journey into health & spirituality, click below to join the Skool community. We look forward to seeing you inside :)</p>

        <a target='_blank' rel="noreferrer" href="https://www.skool.com/adonis-gang/about" className="button" style={{marginTop: '4rem'}}>
          JOIN COMMUNITY
        </a>

      </div>
  	</>
	)
}
