import React from 'react'
import Header from './Header.js'
import './App.css'
import {Link} from 'react-router-dom'

export default function About() {
  return (
    <>
			<Header />

      <div>
        <h1 style={{paddingBottom: '2rem'}}>About The Healing Wand</h1>
        <p style={{fontStyle: 'italic', width: '77%', margin: 'auto'}}>For 7 years, we have been helping people from around the world to “truly heal” – real healing of mind, body, and soul.</p>
        <p style={{fontStyle: 'italic'}}>Here you’ll learn: Who we help, What we do, our Philosophy, Story, and Team members.</p>
      </div>
				
      <div className='about-container'>
        <h2>Who we help</h2>

        <p>We help people looking for both spiritual and physical healing. People looking to overcome something difficult in their lives. People looking to find the natural health and happiness of life again.</p>
        <p>We help people who want to understand their body enough to always live in a state of optimal, vibrant, youthful health. As well those that want to understand their own internal nature, and to find peace with it, and to grow in awareness.</p>
        <p>Basically, we are for anyone who wants to improve their awareness of the 3 aspects of life: mind, body, and soul. Anyone who is committed to living better in these aspects of life – these are the people we help.</p>
      

        <h2 style={{marginTop: '3rem'}}>What we do</h2>

        <p>We are a community that helps people to heal in mind, body, and soul. We teach and guide people on how to live in accordance to the laws of nature, so that people do not suffer needlessly from ignorance. We do this with our online community on Skool(LINK) and through our in-person retreats.</p>
        <p>Our in-person retreats involve health and spiritual practices from many traditions of the world, such as indigenous healing wisdom, ayurveda, yoga, and more.</p>


        <h2 style={{marginTop: '3rem'}}>Our philosophy</h2>

        <p>Our philosophy is to use the best of humanity’s ancient practices and healing wisdom to help people heal, gain awareness, and create a space to come together as a global, enlightened family.</p>
        <p>We use a combination of Ayurveda, Yoga, and Indigenous teachings from North and South America, and other healing practices from around the world.</p>
        <p>We want to see the world improve in a positive, life-affirming direction. We are interested in elevating our awareness and evolving into enlightened human beings.</p>
        <p>Some of the foundations of our philosophy are:</p>
        <ul style={{lineHeight: '2rem'}}>
          <li><b>Awareness</b> of the laws of nature and how they apply to life and to ourselves</li>
          <li><b>Lifestyle</b> changes to live in accordance with Universal Law for health and happiness</li>
          <li><b>Spiritual practices</b> that help to elevate to the next level of awareness</li>
          <li><b>Transmuting</b> our energy and awareness into something to share with the world</li>
          <li><b>Leading</b> and inspiring the community with enlightened leadership</li>
          <li><b>Introspection</b> into the Self as a way of expanding consciousness and improving life</li>
        </ul>

        <h2 style={{marginTop: '3rem'}}>Our story</h2>

        <p>Our story started in 2017 when Bryan Perez left his corporate job to travel the world in search of spiritual knowledge. A shift of perspective gave him a strong desire to learn and experience his own spiritual growth.</p>
        <p>In that year he met spiritual and natural healers from around the world. He spent the next few years living with them, studying natural healing and spirituality, and assisting them with healings.</p>
        <p>Very quickly this small group of healers began hosting retreats for travelers from around the world. These retreats involved many modalities of healing and spirituality, and many people experienced some of the deepest healings in years at these retreats.</p>
        <p>Seeing the amazing benefits for so many people, we started this online community to reach others and spread these powerful practices and knowledge of healing and enlightenment.</p>
        <p>Our goal is for our people to become enlightened and inspired and to make positive changes in the world. We believe all postive changes are aligned with Universal Law.</p>
        <p>Helping people grow physically and spiritually is our mission.</p>

        <h2 style={{marginTop: '3rem'}}>Our team</h2>

        <p>We’re a team of healers, yogis, ayurveda vaidyas, shamans, mystics, musicians, herbalists, and other professionals.</p>

        <ul style={{lineHeight: '2rem'}}>
          <li><b>Bryan Perez –</b> Founder, Ayurveda practitioner from Miami, USA</li>
          <li><b>Dr. Harsh Agarwal –</b> 4th generation Ayurvedic Doctor from Rishikesh, India</li>
          <li><b>Chaman Sirata –</b> Colombian Shaman, mountain man, healer, author</li>
          <li><b>Swami Shyamanand Saraswati –</b> Yogi, community leader, shaman, healer, musician</li>
          <li><b>Carolina Ortiz –</b> Ayurveda practitioner from US</li>
          <li><b>Sabrina Lopez –</b> Ayurveda practitioner from US</li>
          <li><b>Swami Lakshmi –</b> Yogi, shaman, healer, musician</li>
          <li><b>Chaman Nelson –</b> Colombian chaman (shaman), healer</li>
          <li><b>Leonardo –</b> Master of medicine and folk music from Colombia</li>
          <li><b>Irdanti –</b> Master of medicine and folk music from Colombia</li>
          <li><b>Sebastian –</b> Indigenous leader, artist, healer from Colombia</li>
        </ul>

        <h2 style={{marginTop: '3rem'}}>Contacting us</h2>

        <p>The best way to contact us is via email – <b>healingwandtribe@gmail.com</b></p>
        <p>You can follow The Healing Wand on Youtube here and Instagram here.</p>
        <p>You can <Link to="/newsletter" style={{color: '#2B56B8'}}>join our Newsletter here</Link> and get 3 quality emails every week that improve your mind, body, and soul.</p>
        <p>Our Privacy Policy, Terms and conditions and other legal pages are here.</p>
      </div>
  	</>
	)
}
