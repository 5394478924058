import React from 'react'
import Header from './Header.js'
import communityImage from './images/ganga.png'
import retreatsImage from './images/retreats.png'
import {Link} from 'react-router-dom'
import './App.css'

export default function Home() {
  return (
    <>
    <Header />

    <div>
      <h1>How to heal from physical & emotional trauma to live a healthy, happy, and positive life</h1>
      <p className="main-text">We are a worldwide <u>Health & Spirituality Community</u> on the path of healing from physical and emotional traumas, gaining a deep awareness of life, and seeking the ultimate goal of life – enlightenment.</p>

      <div className="cards-container">
        <div className="card">
          <img src={communityImage} alt="Online community" />

          <div className="card-content">
            <h2>Online Community</h2>
            <p>Our online community for people who want to begin their journey into deep healing and awareness. Begin here and go at your own pace from the comfort of your own home.</p>
            
            
            <Link to="/online-community" className='btn-link'>
              <div className="button">
                Learn More
              </div>
            </Link>
          </div>
        </div>

        <div className="card">
          <img src={retreatsImage} alt="In person retreats" />

          <div className="card-content">
            <h2>In-person retreats</h2>
            <p>Live retreats for people who are ready to go deeper into their healing and transformation. Our retreats have been called “life-changing” – they are where deep healing happens.</p>
            
            <Link to="/retreats" className='btn-link'>
              <div className="button">
                Learn More
              </div>
            </Link>

          </div>
        </div>
      </div>
      
    </div>
  </>
)
}